<template>
  <manage title="单位资产" :load-parm="loadParm"></manage>
</template>

<script>
import Manage from './manage'

export default {
  components: {
    Manage
  },
  data () {
    return {
      loadParm: {
        beloneType: '单位资产'
      }
    }
  }
}
</script>
